var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='z-50 sticky right-0 bottom-0 w-full pb-10 pr-10 pointer-events-none' data-cy='toast'>\n  <div\n    x-data=\"{'auto_hide': "
    + alias4(((helper = (helper = lookupProperty(helpers,"toast_auto_hide") || (depth0 != null ? lookupProperty(depth0,"toast_auto_hide") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toast_auto_hide","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":45}}}) : helper)))
    + " }\"\n    x-init='if(auto_hide) setTimeout(() => $el.remove(), 3500)'\n    id='"
    + alias4(((helper = (helper = lookupProperty(helpers,"toast_id") || (depth0 != null ? lookupProperty(depth0,"toast_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toast_id","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":20}}}) : helper)))
    + "'\n    class='shadow-lg bg-white max-w-md ml-auto'\n    role='alert'\n  >\n    <div class='bg-elsred text-white px-5 py-1 h-9'>\n      <strong data-cy='toast_title' class='align-middle' x-text='_(\""
    + alias4(((helper = (helper = lookupProperty(helpers,"toast_title") || (depth0 != null ? lookupProperty(depth0,"toast_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toast_title","hash":{},"data":data,"loc":{"start":{"line":10,"column":68},"end":{"line":10,"column":83}}}) : helper)))
    + "\")'></strong>\n      <button\n        data-cy='close_toast_button'\n        class='absolute right-12 w-5 top-2 h-5 fa-solid fa-xmark pointer-events-auto'\n        @click=\"document.getElementById('"
    + alias4(((helper = (helper = lookupProperty(helpers,"toast_id") || (depth0 != null ? lookupProperty(depth0,"toast_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toast_id","hash":{},"data":data,"loc":{"start":{"line":14,"column":41},"end":{"line":14,"column":53}}}) : helper)))
    + "')?.remove()\"\n        type='button'\n      >\n      </button>\n    </div>\n    <div class='p-5 overflow-y-auto max-h-32 pointer-events-auto'>\n      <div data-cy='toast_message'>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"toast_message") || (depth0 != null ? lookupProperty(depth0,"toast_message") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toast_message","hash":{},"data":data,"loc":{"start":{"line":20,"column":35},"end":{"line":20,"column":54}}}) : helper))) != null ? stack1 : "")
    + "</div>\n      <p\n        id='reload_page_from_toast'\n        class='text-elsred underline cursor-pointer'\n        x-show='is_readonly_document_view'\n        @click='location.reload()'\n        x-text='_(\"Reload\")'\n      ></p>\n    </div>\n  </div>\n</div>";
},"useData":true});