var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <strong x-data='{ current_option_uuid: \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_name_uuid") : depth0), depth0))
    + "\", doc_type: \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_type") : depth0), depth0))
    + "\", show_version: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"show_version") : depth0), depth0))
    + ", current_version: \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"current_version") : depth0), depth0))
    + "\" }'>\n        <div x-show=\"current_option_uuid\"> "
    + ((stack1 = container.invokePartial(require("../option/show_title.handlebars"),depth0,{"name":"../option/show_title","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " </div>\n    </strong>\n    <div data-cy='document-sub-title' class='text-xs' x-data='{ current_option_uuid: \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_comment_uuid") : depth0), depth0))
    + "\" }'>\n      <div x-show=\"current_option_uuid\"> "
    + ((stack1 = container.invokePartial(require("../option/show_subtitle.handlebars"),depth0,{"name":"../option/show_subtitle","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_uid") : depth0), depth0))
    + "_div' x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"x_data") : depth0), depth0))
    + "' :class=\"{'opacity-50': is_readonly_document_view}\">\n  <div id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_uid") : depth0), depth0))
    + "_title' class='pt-1 pb-9' :class=\"{'pointer-events-none': is_readonly_document_view}\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"doc_name_uuid") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n  <div id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_uid") : depth0), depth0))
    + "_tab' class='flex border-b' x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sections_tab_list") : depth0), depth0))
    + "' data-cy=\"sections_tab\">\n    <template x-for='tab in sections_tab_list'>\n      <span class='mr-4'>\n        <button\n          :id='tab.tab_id'\n          x-text='tab.tab_text'\n          type='button'\n          :class=\"{'!text-elsred border-elsred border-b': eval(tab.sec_name), '!text-elsred-light': !eval(tab.sec_name)}\"\n          @click='() => { active_document_tab = tab.tab_text; eval(tab.tab_click) }'\n        ></button>\n      </span>\n    </template>\n  </div>\n  <div\n    id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"doc_uid") : depth0), depth0))
    + "_tab_content'\n    data-objid='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"object_id") : depth0), depth0))
    + "'\n    x-data='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"section_contents_list") : depth0), depth0))
    + "'\n    data-cy=\"sections_tab_content\"\n    :class=\"{'pointer-events-none': is_readonly_document_view}\"\n  >\n    <template\n      x-for='section_content in section_contents_list'\n      x-effect='get_controller(\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conf_uuid") : depth0), depth0))
    + "\").document_view_ready()'\n    >\n      <div\n        :id='section_content[0]'\n        x-show='eval(section_content[1])'\n        class='grid grid-cols-1 w-auto striped'\n      >\n        <template x-for='current_option_uuid in section_content[2]'>\n"
    + ((stack1 = container.invokePartial(require("../option/show_option.handlebars"),depth0,{"name":"../option/show_option","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </template>\n      </div>\n    </template>\n  </div>\n</div>";
},"usePartial":true,"useData":true});