var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div :id=\"'"
    + alias4(((helper = (helper = lookupProperty(helpers,"serial_with_cid") || (depth0 != null ? lookupProperty(depth0,"serial_with_cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"serial_with_cid","hash":{},"data":data,"loc":{"start":{"line":1,"column":11},"end":{"line":1,"column":30}}}) : helper)))
    + "'+'_p'\">\n  <p><b>"
    + alias4(((helper = (helper = lookupProperty(helpers,"serial") || (depth0 != null ? lookupProperty(depth0,"serial") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"serial","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":18}}}) : helper)))
    + "</b>, "
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":30}}}) : helper)))
    + "</p>\n  <div :id=\"'"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":19}}}) : helper)))
    + "' + '_table_div'\"></div>\n</div>";
},"useData":true});